import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";

import { translate, translatePageText } from "../i18n";

import banner from "../assets/images/banners/volunteer-banner.png";
import gfcrLogo from "../assets/images/gfcr-logo[128x128].png";




class Volunteer extends Component {

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBanner
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBanner() {

        return (
            <>
                <div className="banner-container">
                    <img
                        loading="lazy"
                        srcSet={banner}
                        className="banner-img"
                    />
                    <div className="banner-title-text">
                        {translatePageText('volunteer', 'header', 'title')} 
                        <br/>
                        {translatePageText('volunteer', 'header', 'text')}
                    </div>
                </div>
                <style jsx>{`
                    .banner-container {
                        display: flex;
                        flex-direction: column;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        position: relative;
                        width: 100%;
                        align-items: start;
                        overflow: hidden;
                        min-height: 557px;
                        color: #fff;
                        justify-content: center;
                        padding: 206px 60px 168px 87px;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-container {
                            max-width: 100%;
                            padding: 40px 20px 40px 30px;
                        }
                    }
                    .banner-img {
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    .banner-title-text {
                        position: absolute;
                        top: 15%;
                        left: 10%;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        color: #fff;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    

                `}</style>
            </>
        );

    }//END renderBanner

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderDescription
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderDescription() {

        return (
            <>
                
                <div className="main-container">
                    
                    <div className="main-title">{translatePageText('volunteer', 'paragraph1', 'title')}</div>
                    <div className="desc-container1"> 
                        <div className="paragraph-container2">
                            <div className="desc-column1">
                                <div className="desc-text">
                                    {translatePageText('volunteer', 'paragraph1', 'text')}
                                </div>
                            </div>
                            <div className="desc-column2">
                                <Link 
                                    className="call-to-action-btn"
                                    to="/contact"
                                >
                                    {translate('volunteerToday')}!
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="paragraph-container1">
                        <div className="paragraph-title">{translatePageText('volunteer', 'paragraph2', 'title')}</div>
                        <div className="paragraph-container2">
                            <div className="paragraph-text">
                                {translatePageText('volunteer', 'paragraph2', 'text')}
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="paragraph-container1">
                        <div className="paragraph-title">{translatePageText('volunteer', 'paragraph3', 'title')}</div>
                        <div className="paragraph-container2">
                            <div className="paragraph-text">
                                {translatePageText('volunteer', 'paragraph3', 'text')}
                            </div>
                        </div>
                    </div>

                    <div className="paragraph-container1">
                        <div className="paragraph-title">{translatePageText('volunteer', 'paragraph4', 'title')}</div>
                        <div className="paragraph-container2">
                            <div className="paragraph-text">
                                {translatePageText('volunteer', 'paragraph4', 'text')}
                            </div>
                        </div>
                    </div>


                    <div className="paragraph-container1">
                        <div className="paragraph-title">{translatePageText('volunteer', 'paragraph5', 'title')}</div>
                        <div className="paragraph-container2">
                            <div className="paragraph-text">
                                {translatePageText('volunteer', 'paragraph5', 'text')}
                            </div>
                        </div>
                    </div>               
                    
                    <div className={"desc-container1"}>
                        <Link 
                            className="call-to-action-btn"
                            to="/contact"
                        >
                            {translate('volunteerToday')}!
                        </Link>
                    </div>
                    
                    <br />
                    <br />
                    
                </div>
                
                <style jsx>{`
                    
                    .main-container {
                        //background-color: var(--Neutral-800, #97aef1);
                        display: flex;
                        justify-content: center;
                        justify-items: center;
                        align-self: center;
                        align-content: center;
                        align-items: center;
                        margin-top: 48px;
                        width: 100%;
                        //max-width: 812px;
                        flex-direction: column;
                        padding: 0 20px;
                    }

                    .main-title {
                        color: var(--Neutral-800, #292e3d);
                        text-align: center;
                        align-self: center;
                        font: 700 28px Work Sans, sans-serif;
                    }

                    .desc-container1 {
                        margin-top: 64px;
                        margin-bottom: 64px;
                        max-width: 812px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-container1 {
                            max-width: 80%;
                            margin-top: 40px;
                        }
                    }

                    .paragraph-container2 {
                        gap: 20px;
                        display: flex;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-container2 {
                            flex-direction: column;
                            align-items: stretch;
                            gap: 0px;
                        }
                    }  
                    
                    .desc-text {
                        color: var(--Neutral-600, #525b7a);
                        font: 400 16px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-text {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }                    
                    
                    .desc-column1 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 72%;
                        margin-left: 0px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-column1 {
                            width: 100%;
                        }
                    }
                    
                    .desc-column2 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        width: 28%;
                        margin-left: 20px;
                        
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .desc-column2 {
                            width: 100%;
                            margin-top: 32px;
                            margin-left: 0px;
                            justify-content: center;
                            justify-items: center;
                            align-self: center;
                            align-content: center;
                            align-items: center;
                        }
                    }

                    .call-to-action-btn {
                        justify-content: center;
                        border: none;
                        border-radius: 4px;
                        background-color: #ff7727;
                        margin-top: 17px;
                        color: var(--Neutral-White, #fff);
                        text-align: center;
                        text-transform: uppercase;
                        //width: 100%;
                        padding: 12px;
                        font: 600 18px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .volunteer-call-to-action-btn {
                            margin-top: 40px;
                        }
                    }

                    .paragraph-container1 {
                        margin-top: 32px;
                        max-width: 812px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-container1 {
                            max-width: 80%;
                            margin-top: 40px;
                        }
                    }                  
                    .paragraph-title {
                        color: var(--Neutral-800, #292e3d);
                        margin-top: 70px;
                        font: 700 28px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-title {
                            max-width: 100%;
                            margin-top: 40px;
                        }
                    }

                    .paragraph-text {
                        color: var(--Neutral-600, #525b7a);
                        margin-top: 26px;
                        font: 400 16px Work Sans, sans-serif;
                        white-space: pre-wrap;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .paragraph-text {
                            max-width: 100%;
                        }
                    }

                `}</style>
            </>
        );        
        
    }//END renderDescription
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderBanner()}
                {this.renderDescription()}
                {/*{renderSocials()}*/}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing

export default connect()(Volunteer);
