// noinspection t

import english from './en';

export const DEFAULT_LOCALE = 'en';

export const Translations = {
    en: english,
};


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     translate
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function translate(message, locale = DEFAULT_LOCALE) {

    // We're actually asking for 'something' to be translated
    if (message) {
        
        // The translation exists AND the message exists in this translation
        if (Translations[locale] && Translations[locale][message])
            return Translations[locale][message];
        
        // Otherwise try in the default translation
        if (Translations[DEFAULT_LOCALE] && Translations[DEFAULT_LOCALE][message]) 
            return Translations[DEFAULT_LOCALE][message];
        
    }//END if

    return '???';
    
}//END translate


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     getAdopteeInfoBlob
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function getAdopteeInfoBlob(adopteeIndex, locale = DEFAULT_LOCALE) {

    if (adopteeIndex > -1) {

        // The translation exists AND the message exists in this translation
        if (Translations[locale] && Translations[locale]["adoptees"][adopteeIndex])
            return Translations[locale]["adoptees"][adopteeIndex];

        // Otherwise try in the default translation
        if (Translations[DEFAULT_LOCALE] && Translations[DEFAULT_LOCALE]["adoptees"][adopteeIndex])
            return Translations[DEFAULT_LOCALE]["adoptees"][adopteeIndex];

    }//END if
    
    return '???';
    
}//END getAdopteeInfoBlob


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     getAdopteeInfoBlobByName
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function getAdopteeInfoBlobByName(adopteeName, locale = DEFAULT_LOCALE) {

    if (adopteeName) {

        // The translation exists AND the message exists in this translation
        if (Translations[locale] && Translations[locale]["adoptees"])
            return Translations[locale]["adoptees"].find(adoptee => adoptee.name.toLowerCase() === adopteeName.toLowerCase());

        // Otherwise try in the default translation
        if (Translations[DEFAULT_LOCALE] && Translations[DEFAULT_LOCALE]["adoptees"])
            return Translations[DEFAULT_LOCALE]["adoptees"].find(adoptee => adoptee.name.toLowerCase() === adopteeName.toLowerCase());
        
    }//END if

    return '???';
    
}//END getAdopteeInfoBlobByName





/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     translatePageText
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
// Example Usage:
    // getPageTextBlob('about', 'title', 'en');
    // getPageTextBlob('adoption', 'call-for-action-text', 'en');
export function translatePageText(pageName, textName, subTextName = '', locale = DEFAULT_LOCALE) {

    if (pageName && textName) {

        if (Translations[locale]) {

            let pageTextBlob = Translations[locale].pageText.find(el => el.name === pageName);
            
            if (!pageTextBlob)
                return '???';
            
            if (subTextName)
                return pageTextBlob[textName] && pageTextBlob[textName][subTextName] ? pageTextBlob[textName][subTextName] : '???';
            else
                return pageTextBlob[textName] ? pageTextBlob[textName] : '???';

        }//END if
        
    }//END if

    return '???';
    
}//END getPageTextBlob


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
//     translateComponentText
//------------------------------------------
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export function translateComponentText(componentName, textName, locale = DEFAULT_LOCALE) {
    
    if (componentName && textName) {

        if (Translations[locale]) {

            let componentTextBlob = Translations[locale].componentText.find(el => el.name === componentName);
            
            return componentTextBlob[textName] ? componentTextBlob[textName] : '???';

        }//END if
    
        return '???';
        
    }//END if
    
}//END getComponentText

