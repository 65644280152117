import axios from 'axios';
import { FETCH_USER } from './types';
import { translate } from "../i18n";


// Fetches the user data from our DB and dispatches the action reducer (authReducer.js)
//
export const fetchUser = () => {
    
    return async (dispatch) => {
        
        const res = await axios.get('/api/current_user');

        dispatch({ 
            type: FETCH_USER, 
            payload: res.data 
        });
        
    }//END return

}//END fetchUser


// Handles the token received from Stripe and dispatches the action reducer (authReducer.js)
//
export const handleToken = (token) => {
        
    return async (dispatch) => {
        
        const res = await axios.post('/api/stripe', token);

        dispatch({ 
            type: FETCH_USER, 
            payload: res.data 
        });
        
    }//END return

}//END handleToken


// Submits the contact form and sends an email
export const submitContactForm = (contactData) => {
        
    return async (dispatch) => {
        
        const res = await axios.post('/api/mail', contactData);
        
        if (res.status === 200)
            alert(translate('emailSendSuccess'));
        else
            alert(translate('emailSendError'));
        
    }//END return
    
}//END submitContactForm
