import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";

import gfcrLogoXl from "../assets/images/gfcr-logo[1024x1024].png";
import aboutStats from "../assets/images/about-stats.jpg";



class MyAccount extends Component {



    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div style={{"align-content": "center"}}>
                <h2 style={{"text-align": "center"}}>
                    User Profiles
                    <br/>
                    Coming Soon!
                </h2>
            </div>
        );

    }//END render


}//END class Landing

export default connect()(MyAccount);
