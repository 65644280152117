import React from "react";

export default {

    companyName: "Gracie's Cat Rescue",
    
    home: 'Home',
    adoption: 'Adoption',
    volunteer: 'Volunteer',
    donations: 'Donations',
    resources: 'Resources',
    about: 'About',
    contact: 'Contact',
    
    login: 'Login',
    email: 'Email',
    phone: 'Phone',
    emailInputPrompt: 'Your email here',
    password: 'Password',
    
    loading: 'Loading',
    emailSendSuccess: "Email sent, thank you!\n\nWe will get back to you as soon as we can. 🐾❤🐾",
    emailSendError: "Error!\n\nSorry, we could not send that email at this time.",
    
    errorAllFields: "Please fill in all fields.",
    errorInvalidEmail: "Please enter a valid email.",
    errorInvalidPhone: "Please enter a valid phone number.",
    errorInvalidPassword: "Please enter a valid password.",
    errorInvalidEmailPassword: "Invalid email or password.",
    
    makeADifference: 'Make a Difference',
    volunteerToday: 'Volunteer Today',
    
    contactUs: 'Contact Us',
    
    serving: 'Serving',
    social: 'Social',
    newsletter: 'Newsletter',
    subscribe: 'Subscribe',
    
    
    senderName: 'Your Name',
    senderReason: 'Reason for contacting',
    senderMessage: 'Message Text Here',
    
    allFieldsRequired: 'All fields are required',
    submit: 'Submit',
    
    
    adoptees: [
        {
            id: 0,
            name: 'Douglas',
            title: '🌟 Meet Douglas: A Hero of the Streets 🐾',
            shortDescription: "Douglas, with his soft gray fur and soulful eyes, has shown remarkable resilience " +
                "in surviving the harsh realities of life as a stray. Now seeking a loving home, Douglas embodies" +
                " the spirit of perseverance and the promise of a brighter future filled with trust and affection.",
            paragraph1: "Douglas's story is one of remarkable resilience and unyielding spirit. " +
                "With his soft gray fur and soulful eyes, Douglas has faced the harsh realities of life " +
                "as a stray with unmatched courage. Found wandering back alleys, his journey from a " +
                "frightened stray to a hopeful feline seeking a forever home is a testament to the " +
                "strength that resides in all of us.",
            paragraph2: "Despite his trials, Douglas dreams of a quiet corner where he can finally rest," +
                " a warm lap to curl up in, and a gentle hand to reassure him that the world isn't as cruel " +
                "as it once seemed. His eyes now gleam with a spark of hope, reflecting the promise of a brighter future.",
            paragraph3: "By opening your heart to Douglas, you are not just adopting a cat; you are embracing" +
                " a survivor, a symbol of perseverance, and a source of endless affection. Will you be the one " +
                "to give Douglas the safe haven he so richly deserves? Together, you can write the next" +
                " chapter of love and healing. 🌟🐾❤️",
        },
        {
            id: 1,
            name: 'ChewChew',
            title: "🌟 Meet ChewChew: Nurturing Guardian with a Chewing Charm 🐾",
            shortDescription: "ChewChew is a nurturing cat with a heart of gold, known for her loving care of stray cats" +
                " in need and her adorable habit of chewing. With her soft tabby fur and playful personality, she brings " +
                "warmth and joy to any home lucky enough to have her.",
            paragraph1: "ChewChew is a delightful cat whose nurturing heart and quirky habit of chewing have made her a beloved" +
                " figure among the local strays. With her soft tabby fur and bright, inquisitive eyes, ChewChew has always had" +
                " a special knack for sensing when another cat is in need. From providing warmth and companionship to sharing her" +
                " food, ChewChew has taken it upon herself to be the caretaker of her colony, ensuring that no stray is ever left alone or hungry.",
            paragraph2: "Beyond her compassionate nature, ChewChew is known for her adorable and amusing love of chewing. Whether " +
                "it's a cozy blanket, a favorite toy, or even a well-worn shoe, she finds comfort and joy in her quirky habit. This " +
                "unique trait adds a playful charm to her already endearing personality, making every moment spent with her filled with laughter and love.",
            paragraph3: "Now, ChewChew is looking for a forever home where her nurturing instincts and love of chewing can be embraced." +
                " She dreams of a place where she can continue to care for those around her while enjoying the simple pleasures that bring " +
                "her happiness. By adopting ChewChew, you’re welcoming a cat with a heart of gold and a playful spirit, ready to fill your " +
                "home with affection and joy. Will you be the one to give ChewChew the loving sanctuary she so richly deserves? 🌟🐾❤️",
        },
        {
            id: 2,
            name: 'PoofPoof',
            title: '🌟 Meet PoofPoof: A Tale of Cuteness 🐾',
            shortDescription: "PoofPoof, with her golden soft fur and golden eyes, has overcome the challenges of living " +
                "in the wild with remarkable strength and hope. She is now seeking a patient, " +
                "compassionate home where she can continue her journey of trust and love.",
            paragraph1: "PoofPoof's story is a mesmerizing blend of strength and hope. With her lustrous ginger fur, " +
                 "she stands as a testament to survival, having navigated the challenges of the wild with unwavering determination. " +
                 "From cautious glances to the softest purrs, PoofPoof's journey showcases the beauty of trust blossoming slowly," +
                " like the most delicate of flowers.",
            paragraph2: "Seeking a patient and understanding home, PoofPoof yearns to grace someone's life with her presence. " +
                "She envisions a place where the past is embraced, and a future filled with gentle companionship is waiting " +
                "to unfold. PoofPoof's golden eyes, once reflecting the mysteries of starlit nights, now shine with the promise" +
                " of brighter tomorrows.",
            paragraph3: "Will you be the chapter in PoofPoof's story that transforms her tale into one of warmth" +
                " and love? By welcoming PoofPoof into your world, you're inviting the embodiment of resilience " +
                "and the beauty of second chances. Come, meet PoofPoof, and be enchanted by the possibility of " +
                "being her guiding light through the journey ahead. 🌟🐾❤️",
        },
        {
            id: 3,
            name: 'Marshall',
            title: "🌟 Meet Marshall: The Feline Advocate for Justice 🐾",
            shortDescription: "Marshall, with his sleek black-and-white coat, has always strived for justice and protection for" +
                " his fellow strays. Now seeking a forever home, this noble feline hopes to bring his unyielding spirit " +
                "and compassion into a loving environment.",
            paragraph1: "Marshall's story is one of unwavering determination and a quest for fairness, not just for himself," +
                " but for every stray he encountered. With his sleek black-and-white coat, reminiscent of a dapper tuxedo, " +
                "Marshall has always stood out, not just in appearance but in spirit. Found in the heart of a bustling neighborhood," +
                " Marshall was known as the guardian of his colony, always looking out for his fellow strays, ensuring they " +
                "had food and protection from the harsh realities of street life.",
            paragraph2: "This noble feline has a heart as big as his courage. Even in the face of adversity, Marshall never" +
                " wavered in his mission to create a safe environment for those around him. His keen instincts and " +
                "compassionate nature have earned him the respect and admiration of many. Despite his struggles, Marshall " +
                "remains hopeful, his bright green eyes reflecting his unyielding belief in a better world for all.",
            paragraph3: "Now, Marshall is searching for a forever home where his heroic spirit can be cherished " +
                "and where he can finally rest, knowing he’s safe and loved. He dreams of a place where his journey" +
                " for justice is met with warmth and kindness. By adopting Marshall, you’re welcoming a true hero " +
                "into your home, a cat who will bring not only companionship but also a profound sense of purpose and" +
                " resilience. Will you be the one to offer Marshall the sanctuary he so richly deserves? 🌟🐾❤️",
        }
    ],
    
    
    pageText: [
        {
            id: 0,
            name: 'about',
            header: {
                title: 'About Us',
                text: '',
            },
            paragraph1: {
                title: "",
                text: "We are Gracie’s Feral Cat Rescue, a non-profit group of eight " +
                    "volunteers, founded by Jo-Ann MacInnis-Cook, in the Miramichi, New " +
                    "Brunswick area. Our group is named for the kind woman who would " +
                    "bring food out to feed cats in this particular colony in Chatham.",
            },
            paragraph2: {
                title: "",
                text: "Our goal is to trap, spay/neuter then release these cats back into " +
                    "their colonies. We always try to find homes for kittens whenever at " +
                    "all possible, and most have fortunately been placed.",
            },
            paragraph3: {
                title: "",
                text: "To date, we have rescued over 500 cats and kittens and we are " +
                    "currently working with multiple different colonies. We continue to " +
                    "feed them and have made winter shelters for them within their " +
                    "colonies. In addition to spaying and neutering, all cats are vet " +
                    "checked for fleas, mites and any diseases or infections as soon as " +
                    "we have trapped them.",
            },
            paragraph4: {
                title: "",
                text: "Most of our funding has been out-of-pocket but we try to raise as " +
                    "much money as we can by holding donation raffles, yard sales, 50/50 " +
                    "draws, etc. and we gladly accept any donations that come our way.",
            }
        },
        {
            id: 1,
            name: 'adoption',
            header: {
                title: 'Adopt a special someone',
                text: '',
            },
            paragraph1: {
                title: "Adoption",
                text: "Choosing to adopt a feral cat can be a rewarding and impactful decision. " +
                    "Feral cats, often born and raised in the wild, have developed remarkable " +
                    "survival skills and independence. By providing them with a safe and " +
                    "loving home, you not only offer them a chance at a better life but also " +
                    "contribute to the overall well-being of the feral cat population. With " +
                    "patience, understanding, and proper socialization, feral cats can form " +
                    "deep bonds with their adopters, becoming loyal and affectionate " +
                    "companions. Adopting a feral cat not only brings joy and companionship " +
                    "into your life but also helps break the cycle of feral cat " +
                    "overpopulation, ensuring a brighter future for these often misunderstood " +
                    "and deserving feline friends.",
            },
            paragraph2: {
                title: "",
                text: "As we are in our busy season and vet costs have increased, we have updated " + 
                    "our adoption prices. Each cat and kitten is vet checked, FIV / FELV tested, given revolution, " +
                    "first vaccination and provender.",
            },
            paragraph3: {
              title: '',
              text: '$175 spayed or neutered cats or big kittens.'  
            },
            paragraph4: {
              title: '',
              text: "$125 for kittens and once you provide proof of spay/neuter you will receive $50 back."  
            },
            paragraph5: {
                title: "",
                text: "We will check references that you provide and we also need the name of your " +
                    "vet you will be going to. Please use the form below for applying to adopt " +
                    "a cat/kitten.",
            },
            paragraph6: {
                title: '',
                text: '❤ Thank you ❤'
            }
            
        },
        {
            id: 2,
            name: 'contact',
            header: {
                title: 'What can us do for yous?',
                text: "No kitty too small, we love ‘em all!",
                backuptext: "No kitty too small, we'll catch 'em all!"
            }
        },
        {
            id: 3,
            name: 'donations',
            header: {
                title: 'How You Can Help',
                text: ''
            },
            paragraph1: {
                title: "Donate",
                text: "Your generous donations play a crucial role in transforming the lives " +
                    "of feral cats and making a tangible impact on our feline rescue " +
                    "efforts. With your support, we can provide essential medical care, " +
                    "including vaccinations and spay/neuter procedures, ensuring the health " +
                    "and well-being of these vulnerable cats. Your donations also help us " +
                    "provide nutritious food, safe shelter, and comfortable bedding, " +
                    "offering feral cats a chance to thrive. By contributing, you directly " +
                    "contribute to our TNR (Trap-Neuter-Return) programs, which effectively " +
                    "manage feral cat populations, preventing the suffering and " +
                    "overbreeding of these animals. Additionally, your donations enable us " +
                    "to rescue, rehabilitate, and find loving homes for feral cats who are " +
                    "able to transition into domestic life. Together, we can create a " +
                    "compassionate community where every feral cat receives the care and " +
                    "love they deserve. We sincerely appreciate your support and compassion " +
                    "for these remarkable creatures."
            },
            paragraph2: {
                title: '',
                text: 'See below for some of the meaningful ways in which you can help out:'
            },
            paragraph3: {
                title: "Monetary Donations",
                text: "",
                
                element1Key: "Cash",
                element1Value: "",
                
                element2Key: "Cheques/Money Orders",
                element2Value: "",
                
                element3Key: "E-Transfers",
                element3Value: "Online bank transfers can be sent to"
            },

            paragraph4: {
                title: "Food / Supplies Donations",
                text: "",
                listItem1: "Wet Cat Food (Friskies Pate Preferred)",
                listItem2: "Dry Cat Food (Whiskas Preferred)",
                listItem3: "Kitten Dry Food (Kitten Chow Preferred)",
                listItem4: "Temptations Cat Treats",
                listItem5: "Lickable Squeeze Up Treats (A cat favourite!)",
                listItem6: "Cat Toys (Balls, Cat Nip Toys, Feather Chasers, etc.)",
                listItem7: "Litter, Litter Boxes & Scoops",
                listItem8: "Paper Towels",
                listItem9: "Garbage Bags",
                listItem10: "Dish Liquid",
                listItem11: "Cleaners",
                listItem12: "Disinfectant Wipes",
            },

            paragraph5: {
                title: "Alternate Ways To Give",
                text: "",
                
                element1Key: "Canadian Tire Money",
                element1Value: "Donate your Canadian Tire money and we can use it to buy much-needed supplies " +
                    "such as cleaners, paper towels, and kitty litter. Every dollar we save " +
                    "goes to help another animal.",
                
                element2Key: "Recyclables",
                element2Value: "Save your recyclables and bring them to [_____], where the proceeds can be " +
                    "credited to Gracie’s Feral Cat Rescue account.",
                
                element3Key: "Donation Cans",
                element3Value: "Wondering what to do with your spare change? Be on the lookout for our Gracie’s Feral " +
                    "Cat Rescue donation cans at local businesses."
            }
        },
        {
            id: 4,
            name: 'landing',
            header: {
                title: 'Rescuing lives, one toebean at a time',
                text: ''
            },
            paragraph1: {
                title: 'Adoption',
                text: "Adopt a cat and make its life better! Adopting a feral cat offers a " +
                    "chance for a better life, creates a rewarding bond, and helps break " + 
                    "the cycle of feral cat overpopulation, ensuring a brighter future for " + 
                    "these deserving feline friends."
            }
        },
        {
            id: 5,
            name: 'resources',
            header: {
                title: 'Resources & Information',
                text: '',
            },
            paragraph1: {
                title: 'What Is A Feral Cat?',
                text: "Feral cats are cats that were born and raised in the wild or have been " + 
                    "abandoned and had to fend for themselves. Unlike pet cats, feral cats " +
                    "haven't had much human interaction, so they may be afraid of people and " + 
                    "prefer to live outside. They are very independent and have developed " +
                    "survival skills to find food and shelter on their own."
            },
            paragraph2: {
                title: 'How You Can Help',
                text: "When it comes to caring for feral cats, there are a few important " + 
                    "things to keep in mind. Firstly, it's essential to provide them with " +
                    "a safe and comfortable shelter, such as a cozy outdoor cat house or a " +
                    "specially designed feral cat shelter. These shelters protect them " +
                    "from harsh weather and give them a safe place to rest." +
                    "\n\n" +
                    "Feeding feral cats is also important. Leaving out food and fresh water " +
                    "in a designated feeding area helps ensure they have enough to eat and " +
                    "drink. It's best to use sturdy bowls or dishes that won't tip over easily. " +
                    "Providing regular meals helps them stay healthy and can also help you " +
                    "build trust with them over time." +
                    "\n\n" +
                    "While feral cats may not be comfortable with close human interaction, " +
                    "it's still crucial to look out for their well-being. Keeping an eye on " +
                    "their overall health is important. If you notice any signs of illness " +
                    "or injury, it's best to contact a local animal shelter or a veterinarian " + 
                    "who can provide advice on how to help them." 
            },
            paragraph3: {
                title: 'Respect Their Boundaries',
                text: "Remember, it's essential to respect a feral cat's boundaries and not force" +
                    "them to interact with you. Over time, some feral cats may become more comfortable" +
                    "around people, but it's a slow process that requires patience and understanding." +
                    "You can earn their trust by being consistent, providing food and shelter," +
                    "and giving them space when they need it." +
                    "\n\n" +
                    "If you want to make a difference in the lives of feral cats, you can support local" +
                    "feral cat rescue organizations or volunteer to help with trap-neuter-return (TNR)" +
                    "programs. TNR involves safely trapping feral cats, spaying or neutering them to" +
                    "prevent overpopulation, and returning them to their outdoor homes." +
                    "\n\n" +
                    "By caring for feral cats and helping them live healthier lives, you are making" +
                    "a positive impact on their well-being and contributing to the welfare of the cat" +
                    "community as a whole. Your compassion and efforts can truly make a difference" +
                    "in their lives."
            }
        },
        {
            id: 6,
            name: 'volunteer',
            header: {
                title: 'Soft Kitty',
                text: 'Warm Kitty'
            },
            paragraph1: {
                title: 'Volunteer Your Time',
                text: "Gracie’s Feral Cat Rescue operates on a year-round basis and " +
                    "depends heavily on its dedicated volunteers. The various tasks " +
                    "involved with rescuing, rehabilitating, and finding homes for " + 
                    "these cats and kittens require people with a variety of talents " +
                    "who are willing to give freely of their time. The following "  +
                    "scenarios will give you a better understanding of how we operate " +
                    "and how you may be able to help us:"
            },
            paragraph2: {
                title: 'Shelter Volunteers',
                text: "Shelter Volunteers are our most needed type of volunteer. Shelter " +
                    "Volunteers work on a rotating weekly schedule that best suites them. " + 
                    "You could volunteer weekly, bi-weekly or even just once a month – " +
                    "whatever works best for you! Shelter Volunteers are responsible for " + 
                    "tending to the cats in our care at the shelter. This includes feeding, " +
                    "cleaning litter boxes, keeping the shelter tidy and socialization of " + 
                    "our resident cats. When you ask other shelter volunteers, they’ll tell " + 
                    "you the job is extremely fulfilling – especially watching first hand a " + 
                    "cat who arrived scared and shut down begin to open up, trust humans " +
                    "and accept affection."
            },
            paragraph3: {
                title: 'Rescue',
                text: "Typically, once we receive a report of feral cats or kittens, we need " +
                    "local volunteers to trap them and transport them to the Rescue or a " +
                    "foster home. The animals will also require rides to and from the " + 
                    "veterinary clinic."
            },
            paragraph4: {
                title: 'Rehabilitation',
                text: "Most of these animals will need foster homes arranged for them. These " +
                    "homes must be stocked with food, litter, and other supplies, which " +
                    "also have to be purchased and delivered."
            },
            paragraph5: {
                title: 'Finding Homes',
                text: "We rely heavily on our website and Facebook to advertise our adoptable " +
                    "animals and keep you posted on the others. It takes many hours each " +
                    "day to keep both of them updated and also respond to all the inquiries " +
                    "that we receive. However, the advantage of using computers means that " +
                    "volunteers can do the work at any time and from any location!" +
                    "\n\n" +
                    "As you can see, almost anyone can play a part in our operation. The " +
                    "more volunteers we have, the better we are able to respond to the " +
                    "needs of feral cats and kittens in our area. Whatever your interests " +
                    "or personal availability, please CONTACT US to see how you can help " +
                    "\n"
            }
        }
    ],
    
    
    componentText: [
        {   
            id: 0,
            name: 'team-experience',
            title: '10+ Years Experience',
            text: "Our team has been trapping, spaying/neutering, and finding " +
                "loving homes for feral kittens in our local area since 2013.",
        }
    ]
    
    
};