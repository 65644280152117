import React, { Component } from 'react';
import { connect } from 'react-redux';

import infoConstants from "../constants/infoConstants";
import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";
import { translatePageText } from "../i18n";

import banner from "../assets/images/banners/donations-banner.png";
import donateWithPayPal from "../assets/images/donate-with-paypal.png";
import suppliesExample from "../assets/images/supplies-example.jpg";
import gfcrLogo from "../assets/images/gfcr-logo[128x128].png";




class Donations extends Component {

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBanner
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBanner() {

        return (
            <>
                <div className="banner-container">
                    <img
                        loading="lazy"
                        srcSet={banner}
                        className="banner-img"
                    />
                    <div className="banner-title-text">
                        {translatePageText('donations', 'header', 'title')}
                    </div>
                </div>
                <style jsx="true">{`
                    .banner-container {
                        display: flex;
                        flex-direction: column;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        position: relative;
                        width: 100%;
                        align-items: start;
                        overflow: hidden;
                        min-height: 557px;
                        color: #fff;
                        justify-content: center;
                        padding: 206px 60px 168px 87px;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-container {
                            max-width: 100%;
                            padding: 40px 20px 40px 30px;
                        }
                    }
                    .banner-img {
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    .banner-title-text {
                        position: absolute;
                        top: 10%;
                        right: 10%;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        color: #fff;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .banner-title-text {
                            left: 10%;
                        }
                    }

                `}</style>
            </>
        );

    }//END renderBanner
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderBlurbs
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderBlurbs() {
        return (
            <>
                                
                <div className="main-container">

                    <div className="main-container-2">
                    
                        <div className="main-title">{translatePageText('donations', 'paragraph1', 'title')}</div>
                        <div className="desc-container1">
                            <div className="paragraph-container2">
                                <div className="paragraph-text">
                                    {translatePageText('donations', 'paragraph1', 'text')}
                                    <br />
                                    <br />
                                    {translatePageText('donations', 'paragraph2', 'text')}
                                </div>
                            </div>
                        </div>
    
                        <div className="paragraph-container1">
                            <div className="paragraph-title">{translatePageText('donations', 'paragraph3', 'title')}</div>
                            <div className="paragraph-container2">
                                <div className="key-value-pair-list">
                                    {/*<span style={{fontWeight: 700}}>Cash:</span> Donations can be dropped*/}
                                    {/*off at the main office in [<i>{infoConstants.geoAddress}</i>], where they will be credited to our*/}
                                    {/*account. Please indicate that the funds are for Gracie’s Feral Cat*/}
                                    {/*Rescue.*/}
                                    {/*<br />*/}
                                    {/*<br />*/}
                                    {/*<span style={{fontWeight: 700}}>Cheques/Money Orders:</span> Cheques*/}
                                    {/*or money orders, made payable to Gracie’s Feral Cat Rescue, can be*/}
                                    {/*mailed to [<i>{infoConstants.geoAddress}</i>].*/}
                                    <br />
                                    <br />
                                    <span style={{fontWeight: 700}}>{translatePageText('donations', 'paragraph3', 'element3Key')}: </span>
                                    {translatePageText('donations', 'paragraph3', 'element3Value')}: [<i>{infoConstants.emailAddress}</i>]
                                    <br />
                                    <br />
                                    {/*<span style={{fontWeight: 700}}>Paypal:</span> Make a donation using*/}
                                    {/*your Paypal account or credit card.*/}
                                </div>
                            </div>
                        </div>
                        
                        {/*<img*/}
                        {/*    loading="lazy"*/}
                        {/*    srcSet={donateWithPayPal}*/}
                        {/*    className="donate-with-paypal-btn"*/}
                        {/*/>*/}
                        <div>
                            <br />
                            <br />
                        </div>
                        <div className="paragraph-container1">
                            <div className="column-container">
                                <div className="supplies-column1">
                                    <div className="paragraph-title-no-top-margin">{translatePageText('donations', 'paragraph4', 'title')}</div>
                                    <div className="bullet-list-container">
                                        • {translatePageText('donations', 'paragraph4', 'listItem1')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem2')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem3')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem4')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem5')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem6')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem7')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem8')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem9')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem10')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem11')}
                                        <br />
                                        • {translatePageText('donations', 'paragraph4', 'listItem12')}
                                    </div>
                                </div>
                                <div className="supplies-column2">
                                    <img
                                        loading="lazy"
                                        srcSet={suppliesExample}
                                        className="supplies-img"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="paragraph-container1">
                            <div className="paragraph-title">{translatePageText('donations', 'paragraph5', 'title')}</div>
    
                                <div className="paragraph-container2">
                                    <div className="key-value-pair-list">
                                        <span style={{fontWeight: 700}}>{translatePageText('donations', 'paragraph5', 'element1Key')}: </span>
                                        {translatePageText('donations', 'paragraph5', 'element1Value')}
                                        <br />
                                        <br />
                                        
                                        {/*<span style={{fontWeight: 700}}>Recyclables:</span> Save your*/}
                                        {/*recyclables and bring them to [_____], where the proceeds can be*/}
                                        {/*credited to Gracie’s Feral Cat Rescue account.*/}
                                        {/*<br />*/}
                                        {/*<br />*/}
                                        
                                        <span style={{fontWeight: 700}}>{translatePageText('donations', 'paragraph5', 'element3Key')}: </span>
                                        {translatePageText('donations', 'paragraph5', 'element3Value')}
                                    </div>
                                </div>
    
                        </div>
                        <div>
                            <br />
                            <br />
                            <br />
                        </div>
                    
                        
                    </div>
                </div>
                
                <style jsx="true">{`
                .main-container {
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-content: center;
                    align-items: center;
                    margin-top: 48px;
                    width: 100%;
                    //max-width: 812px;
                    flex-direction: column;
                    padding: 0 20px;
                }
                
                .main-container-2 {
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-content: center;
                    align-items: center;
                    width: 100%;
                    flex-direction: column;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .main-container-2 {
                        width: 80%;
                    }
                }
                

                .main-title {
                    color: var(--Neutral-800, #292e3d);
                    text-align: center;
                    align-self: center;
                    font: 700 28px Work Sans, sans-serif;
                }

                .desc-container1 {
                    margin-top: 64px;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    max-width: 812px;
                }
                
                .paragraph-container2 {
                    gap: 20px;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                }

                .desc-text {
                    color: var(--Neutral-600, #525b7a);
                    font: 400 16px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .desc-text {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }

                .desc-column1 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 72%;
                    margin-left: 0px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .desc-column1 {
                        width: 100%;
                    }
                }

                .desc-column2 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 28%;
                    margin-left: 20px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .desc-column2 {
                        width: 100%;
                    }
                }
                
                .paragraph-container1 {
                    margin-top: 32px;
                    max-width: 812px;
                    width: 100%;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .paragraph-container1 {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }
                .paragraph-container2 {
                    gap: 20px;
                    display: flex;
                    width: 100%;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .paragraph-container2 {
                        flex-direction: column;
                        align-items: stretch;
                        gap: 0px;
                    }
                }
                
                .paragraph-title {
                    color: var(--Neutral-800, #292e3d);
                    margin-top: 70px;
                    font: 700 28px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .paragraph-title {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }

                .paragraph-text {
                    color: var(--Neutral-600, #525b7a);
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    font: 400 16px Work Sans, sans-serif;
                }
                //@media (max-width: ${styleConstants.mobileScreenWidth}px) {
                //    .paragraph-text {
                //        width: 80%;
                //    }
                //}
                .donate-with-paypal-btn {
                    aspect-ratio: 3.13;
                    align-self: left;
                    object-fit: contain;
                    object-position: left;
                    width: 300px;
                    margin-top: 15px;
                    max-width: 100%;
                }
                
                .key-value-pair-list {
                    color: var(--Neutral-600, #525b7a);
                    margin-top: 20px;
                    justify-content: center;
                    justify-items: center;
                    align-self: center;
                    align-content: center;
                    align-items: center;
                    font: 400 16px Work Sans, sans-serif;
                    width: 100%;
                }
                //@media (max-width: ${styleConstants.mobileScreenWidth}px) {
                //  .key-value-pair-list {
                //    width: 80%;
                //  }
                //}

                .div-6 {
                  margin-top: 65px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .div-6 {
                    max-width: 100%;
                    margin-top: 40px;
                  }
                }
                
                
                .column-container {
                  gap: 20px;
                  display: flex;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .column-container {
                    flex-direction: column;
                    align-items: stretch;
                    gap: 0px;
                  }
                }
                
                .paragraph-title-no-top-margin {
                  color: var(--Neutral-800, #292e3d);
                  font: 700 28px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .paragraph-title-no-top-margin {
                    max-width: 100%;
                  }
                }
                
                .bullet-list-container {
                  color: var(--Neutral-600, #525b7a);
                  margin-top: 48px;
                  font: 400 16px Work Sans, sans-serif;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .bullet-list-container {
                        max-width: 100%;
                        margin-top: 20px;
                    }
                }


                .supplies-column1 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 60%;
                    margin-left: 0px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .supplies-column1 {
                        width: 100%;
                    }
                }                
                
                .supplies-column2 {
                  display: flex;
                  flex-direction: column;
                  line-height: normal;
                  width: 40%;
                  margin-left: 20px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .supplies-column2 {
                        width: 100%;
                        margin-left: 0px;  
                    }
                }
                
                .supplies-img {
                  aspect-ratio: 0.76;
                  object-fit: auto;
                  object-position: center;
                  width: 100%;
                  flex-grow: 1;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .supplies-img {
                    margin-top: 40px;
                  }
                }

              `}</style>
            </>
        );
    }//END renderBlurbs
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderBanner()}
                {this.renderBlurbs()}
                {renderSocials()}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing

export default connect()(Donations);