import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import 'react-multi-carousel/lib/styles.css';

import AdoptionCarousel from "./AdoptionCarousel";

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";
import {translate, translateComponentText, translatePageText} from "../i18n";

import landingBanner from "../assets/images/banners/landing-banner.jpg";
import donationCat from "../assets/images/donation-cat[1024x1024].png";
import scaredKitten from "../assets/images/scared-kitten.png";
import superheroCat from "../assets/images/superhero-cat[1024x1024].png";
import aboutStats from "../assets/images/about-stats.jpg";


class Landing extends Component {
    

    renderPlaceholderComponent() {

        return (
            <div className="flex flex-col justify-center bg-white shadow-2xl">
                <div className="flex gap-5 justify-between items-center px-20 py-0.5 w-full text-sm bg-white text-zinc-900 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet="..."
                        className="shrink-0 self-stretch w-12 aspect-square"
                    />
                    <div className="flex gap-5 justify-between self-stretch my-auto max-md:flex-wrap">
                        <div>Store</div>
                        <div>Adoption</div>
                        <div>Volunteer</div>
                        <div>Donations</div>
                        <div>Resources</div>
                        <div>Community</div>
                        <div className="font-semibold text-fuchsia-400">Contact Us</div>
                    </div>
                    <img
                        loading="lazy"
                        srcSet="..."
                        className="shrink-0 self-stretch my-auto aspect-square w-[42px]"
                    />
                </div>
                <div className="overflow-hidden relative flex-col justify-center items-start pt-52 pr-16 pb-44 pl-20 w-full text-4xl font-semibold text-white min-h-[557px] max-md:py-10 max-md:pr-5 max-md:pl-8 max-md:max-w-full">
                    <img
                        loading="lazy"
                        srcSet="..."
                        className="object-cover absolute inset-0 size-full"
                    />
                    Rescuing lives, one toebean at a time.
                </div>
                <div className="flex flex-col items-center px-12 py-8 w-full bg-white max-md:px-5 max-md:max-w-full">
                    <div className="text-3xl font-bold text-center text-slate-800">
                        Adoption
                    </div>
                    <div className="mt-4 text-base text-center text-slate-600 max-md:max-w-full">
                        Adopt a cat and make its life better! Adopting a feral cat offers a
                        chance for a better life, creates a rewarding bond, and helps break
                        the cycle of feral cat overpopulation, ensuring a brighter future for
                        these deserving feline friends.
                    </div>
                    <div className="self-stretch mt-2.5 max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow justify-center items-center px-3 pt-4 pb-7 w-full bg-white rounded-sm max-md:mt-9">
                                    <img
                                        loading="lazy"
                                        srcSet="..."
                                        className="w-48 aspect-square"
                                    />
                                    <div className="mt-3.5 text-base font-bold text-slate-700">
                                        Douglas
                                    </div>
                                    <div className="self-stretch mt-3.5 text-xs text-slate-400">
                                        Nullam senectus porttitor in eget. Eget rutrum leo interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow justify-center items-center px-3 pt-4 pb-7 w-full bg-white rounded-sm max-md:mt-9">
                                    <img
                                        loading="lazy"
                                        srcSet="..."
                                        className="w-48 aspect-square"
                                    />
                                    <div className="mt-3.5 text-base font-bold text-slate-700">
                                        Chew-chew
                                    </div>
                                    <div className="self-stretch mt-3.5 text-xs text-slate-400">
                                        Nullam senectus porttitor in eget. Eget rutrum leo interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow justify-center items-center px-3 pt-4 pb-7 w-full bg-white rounded-sm max-md:mt-9">
                                    <img
                                        loading="lazy"
                                        srcSet="..."
                                        className="w-48 aspect-square"
                                    />
                                    <div className="mt-3.5 text-base font-bold text-slate-700">
                                        Poofpoof
                                    </div>
                                    <div className="self-stretch mt-3.5 text-xs text-slate-400">
                                        Nullam senectus porttitor in eget. Eget rutrum leo interdum.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow justify-center items-center px-3 pt-4 pb-7 w-full bg-white rounded-sm max-md:mt-9">
                                    <img
                                        loading="lazy"
                                        srcSet="..."
                                        className="w-48 aspect-square"
                                    />
                                    <div className="mt-3.5 text-base font-bold text-slate-700">
                                        Marshall
                                    </div>
                                    <div className="self-stretch mt-3.5 text-xs text-slate-400">
                                        Nullam senectus porttitor in eget. Eget rutrum leo interdum.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden px-20 pt-6 pb-20 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                            <img
                                loading="lazy"
                                srcSet="..."
                                className="mt-20 w-full rounded-md shadow-lg aspect-square max-md:mt-10"
                            />
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col text-3xl font-bold text-center text-white max-md:mt-10">
                                <div>Make A Difference</div>
                                <img
                                    loading="lazy"
                                    srcSet="..."
                                    className="self-center mt-14 max-w-full rounded-sm shadow-lg aspect-[1.49] w-[198px] max-md:mt-10"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                            <img
                                loading="lazy"
                                srcSet="..."
                                className="mt-20 w-full rounded-sm shadow-lg aspect-square max-md:mt-10"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center px-16 pt-20 pb-11 w-full bg-white max-md:px-5 max-md:max-w-full">
                    <div className="mt-2.5 w-full max-w-[775px] max-md:max-w-full">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
                                <img
                                    loading="lazy"
                                    srcSet="..."
                                    className="grow w-full rounded-sm border border-white border-solid shadow-lg aspect-[1.06] max-md:mt-10 max-md:max-w-full"
                                />
                            </div>
                            <div className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col self-stretch my-auto max-md:mt-10">
                                    <div className="text-5xl font-bold text-indigo-700">
                                        10 Years Experience
                                    </div>
                                    <div className="mt-9 text-base text-slate-600">
                                        Our team has been trapping, spaying/neutering, and finding
                                        loving homes for feral kittens in our local area since 2013.
                                    </div>
                                    <div className="justify-center px-4 py-3 mt-8 text-sm font-semibold text-center text-white bg-indigo-700 rounded-sm max-md:px-5">
                                        Contact Us
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-5 justify-between px-20 py-14 w-full bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col items-start text-base">
                        <div className="flex gap-2.5 ml-2.5">
                            <div className="font-medium text-indigo-700">Address:</div>
                            <div className="capitalize text-slate-800">
                                39 Elgin St. Miramichi, NB
                            </div>
                        </div>
                        <div className="flex gap-5 justify-between mt-6 ml-2.5">
                            <div className="font-medium text-indigo-700">Phone:</div>
                            <div className="capitalize text-slate-800">+1 506 867 5309</div>
                        </div>
                        <div className="flex gap-5 justify-between mt-6 ml-2.5 whitespace-nowrap">
                            <div className="font-medium text-indigo-700">Email:</div>
                            <div className="text-black">hello@graciescatrescue.com</div>
                        </div>
                        <div className="flex gap-1 self-stretch mt-4 text-2xl font-bold text-indigo-700">
                            <img
                                loading="lazy"
                                srcSet="..."
                                className="shrink-0 w-12 aspect-square"
                            />
                            <div className="flex-auto my-auto italic">Gracie’s Cat Rescue</div>
                        </div>
                    </div>
                    <div className="flex flex-col my-auto">
                        <div className="text-base font-medium text-indigo-700">
                            Newsletter:
                        </div>
                        <div className="flex gap-1.5 mt-5">
                            <div className="justify-center self-start py-2 text-lg bg-white rounded-sm border border-solid border-zinc-200 text-slate-400">
                                Your email here
                            </div>
                            <div className="justify-center px-4 py-2 text-sm font-semibold text-center text-white whitespace-nowrap bg-orange-500 rounded-sm">
                                Subscribe
                            </div>
                        </div>
                        <div className="mt-9 text-base font-medium text-indigo-700">
                            Social:
                        </div>
                        <div className="flex gap-3.5 mt-4 max-md:pr-5">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c53a77a8847dc3bf2672e081868a40fa8df12b81d97dc2fef8e07d177afa4625?"
                                className="shrink-0 aspect-[1.03] w-[30px]"
                            />
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/660224c14d3f6dba78e45e1965d0e021dda629b5bf9f3d24e8db8c27ae974890?"
                                className="shrink-0 aspect-[1.03] w-[30px]"
                            />
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/439ddf32be243fc57a1b7586c17c5f10b19112a07ecd160be7c3355be0eb5252?"
                                className="shrink-0 aspect-[1.03] w-[30px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="justify-center items-start py-5 pr-16 pl-20 w-full text-xs text-white bg-indigo-700 max-md:pr-5 max-md:pl-8 max-md:max-w-full">
                    Gracie’s Cat Rescue © 2023. All Rights Reserved
                </div>
            </div>
        );

    }//END myPlaceholderComponent

    renderBanner() {

        return (
            <>
                <div className="banner-container">
                    <img
                        loading="lazy"
                        srcSet={landingBanner}
                        className="banner-img"
                    />
                    <div className="banner-title-text">{translatePageText('landing', 'header', 'title')}</div>
                </div>
                <style jsx="true">{`
                    .banner-container {
                        display: flex;
                        flex-direction: column;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        position: relative;
                        width: 100%;
                        align-items: start;
                        overflow: hidden;
                        min-height: 557px;
                        color: #fff;
                        justify-content: center;
                        padding: 206px 60px 168px 87px;
                        font: 600 40px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                      .banner-container {
                        max-width: 100%;
                        padding: 40px 20px 40px 30px;
                      }
                    }
                    .banner-img {
                      position: absolute;
                      inset: 0;
                      height: 100%;
                      width: 100%;
                      object-fit: cover;
                      object-position: center;
                    }
                    .banner-title-text {
                        position: absolute;
                        text-shadow: 0px 1px 16px rgba(0, 0, 0, 0.55);
                        color: #fff;
                        font: 600 40px Work Sans, sans-serif;
                    }

              `}</style>
            </>
        );
        
    }//END renderBanner
    
  
    renderAdoptionSection() {

        return (
            <>
                <div className="landing-div">
                    <div className="title-div">{translatePageText('landing', 'paragraph1', 'title')}</div>
                    <div className="reg-text-div">
                        {translatePageText('landing', 'paragraph1', 'text')}
                    </div>
                    
                </div>
                <style jsx="true">{`
                    .landing-div {
                        //background-color: #f87070;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        align-items: center;
                        padding: 20px 0px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .landing-div {
                            max-width: 100%;
                            padding: 0 20px;
                        }
                    }

                    .title-div {
                        color: var(--Neutral-800, #292e3d);
                        text-align: center;
                        padding-top: 32px;
                        font: 700 28px Work Sans, sans-serif;
                    }

                    .reg-text-div {
                        color: var(--Neutral-600, #525b7a);
                        text-align: center;
                        margin-top: 64px;
                        max-width: 80%;
                        font: 400 16px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .reg-text-div {
                            max-width: 80%;
                        }
                    }

                    

                `}</style>
            </>
        );
        
    }//END renderHeader
    
    
    renderCallForHelp() {

        return (
            <>
                <div className="landing-callforhelp-container-1">
                    {/*<img*/}
                    {/*    loading="lazy"*/}
                    {/*    srcSet={curvyAbstract}*/}
                    {/*    className="landing-callforhelp-banner"*/}
                    {/*/>*/}
                    <div className="landing-callforhelp-container-2">
                        <div className="landing-callforhelp-column-1">
                            <img
                                loading="lazy"
                                srcSet={donationCat}
                                className="landing-callforhelp-outer-img"
                            />
                        </div>
                        <div className="landing-callforhelp-column-2">
                            <div className="landing-callforhelp-column-2a">
                                <div className="landing-callforhelp-title">{translate("makeADifference")}</div>
                                <img
                                    loading="lazy"
                                    srcSet={scaredKitten}
                                    className="landing-callforhelp-inner-img"
                                />
                            </div>
                        </div>
                        <div className="landing-callforhelp-column-3">
                            <img
                                loading="lazy"
                                srcSet={superheroCat}
                                className="landing-callforhelp-outer-img"
                            />
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                .landing-callforhelp-container-1 {
                    //overflow: hidden;
                    width: 100%;
                    //padding: 23px 80px 79px;
                    margin-top: 96px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .landing-callforhelp-container-1 {
                    max-width: 100%;
                    padding: 0 20px;
                  }
                }
                .landing-callforhelp-container-2 {
                    gap: 20px;
                    display: flex;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .landing-callforhelp-container-2 {
                    flex-direction: column;
                    align-items: stretch;
                    gap: 0px;
                  }
                }
                .landing-callforhelp-banner {
                    position: relative;
                    inset: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                
                .landing-callforhelp-column-1 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 33%;
                    margin-bottom: 32px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .landing-callforhelp-column-1 {
                    width: 100%;
                  }
                }
                .landing-callforhelp-column-2 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 33%;
                    margin-bottom: 32px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .landing-callforhelp-column-2 {
                        width: 100%;
                    }
                }
                .landing-callforhelp-column-2a {
                    display: flex;
                    flex-direction: column;
                    font-size: 28px;
                    color: #fff;
                    font-weight: 700;
                    text-align: center;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .div-3 {
                        margin-top: 40px;
                    }
                }                
                .landing-callforhelp-column-3 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 33%;
                    margin-bottom: 32px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                    .landing-callforhelp-column-3 {
                        width: 100%;
                    }
                }
                .landing-callforhelp-title {
                    text-shadow: 0px 1px 8px #000, 0px 1px 16px rgba(0, 0, 0, 0.75);
                    font-family: Work Sans, sans-serif;
                }                
                
                
                .landing-callforhelp-outer-img {
                  aspect-ratio: 1;
                  object-fit: auto;
                  object-position: center;
                  width: 100%;
                  border-radius: 5px;
                  //box-shadow: 0px 0px 27.074px -1.463px rgba(17, 24, 39, 0.3);
                  margin-top: 64px;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .landing-callforhelp-outer-img {
                    margin-top: 40px;
                  }
                }
                .landing-callforhelp-inner-img {
                  aspect-ratio: 1.49;
                  object-fit: cover;
                  object-position: center;
                  width: 198px;
                  border-radius: 2.927px;
                  //box-shadow: 0px 0px 27.074px -1.463px rgba(17, 24, 39, 0.3);
                  align-self: center;
                  margin-top: 54px;
                  max-width: 100%;
                }
                @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                  .landing-callforhelp-inner-img {
                    margin-top: 40px;
                  }
                }

                
              `}</style>
            </>
        );
        
    }//END renderCallForHelp
    
    
    renderExperience() {
        
        return (
            <>
                <div className="main-container">
                    
                    <div className="container-row">

                        <div className="row-content-75">
                            <img
                                loading="lazy"
                                srcSet={aboutStats}
                                className="logo-extra-large"
                            />
                        </div>

                        <div className="row-content-25">
                            <div className="exp-blurb-column1">

                                <div className="exp-blurb-column2">
                                    <div className="exp-blurb-title">{translateComponentText('team-experience', 'title')}</div>
                                    <div className="exp-blurb-text">
                                        {translateComponentText('team-experience', 'text')}
                                    </div>
                                    
                                    
                                    <Link 
                                        className="contact-us-btn"
                                        to="/contact"
                                    >
                                        {translate('contactUs')}
                                    </Link>
                                    
                                </div>

                            </div>

                        </div>

                    </div>


                </div>

                <style jsx="true">{`
                    .main-container {
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                        padding: 32px 32px 32px;
                        width: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .main-container {
                            padding: 0 20px;
                        }
                    }

                    .container-row {
                        
                        display: flex;
                        margin-top: 15px;
                        //gap: 20px;
                        justify-content: center;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .container-row {
                            flex-wrap: wrap;
                        }
                    }

                    .row-content {
                        justify-content: flex-end;
                        border-radius: 1.463px;
                        //box-shadow: 0px 14.635px 17.562px -2.927px rgba(17, 24, 39, 0.1),
                        0px 5.854px 5.854px -2.927px rgba(17, 24, 39, 0.04);
                        display: flex;
                        width: 50%;
                        max-width: 100%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content {
                            margin-right: 10px;
                            padding: 0 20px;
                        }
                    }

                    .row-content-colored {
                        justify-content: flex-end;
                        border-radius: 1.463px;
                        box-shadow: 0px 14.635px 17.562px -2.927px rgba(17, 24, 39, 0.1),
                        0px 5.854px 5.854px -2.927px rgba(17, 24, 39, 0.04);
                        background-color: #4030b7;
                        display: flex;
                        width: 50%;
                        max-width: 100%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-colored {
                            margin-right: 10px;
                            padding: 0 20px;
                        }
                    }

                    .row-content-75 {
                        justify-content: center;
                        border-radius: 1.463px;
                        width: 65%;
                        max-width: 65%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-75 {
                            width: 100%;
                            max-width: 100%;
                            padding: 0px 0px;
                            align-content: center;
                        }
                    }

                    .row-content-25 {
                        //background-color: aqua;
                        justify-content: center;
                        align-content: center;
                        //border-radius: 1.463px;
                        display: flex;
                        width: 35%;
                        max-width: 35%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-25 {
                            //background-color: coral;
                            margin-right: 10px;
                            width: 100%;
                            max-width: 100%;
                            padding: 0px 16px;
                            margin-bottom: 128px;
                            align-content: center;
                            
                        }
                    }


                    .logo-extra-large {
                        aspect-ratio: 1;
                        align-self: center;
                        align-items: center;
                        //object-fit: auto;
                        object-position: center;
                        width: 100%;
                    }


                    .about-title-white {
                        color: #fff;
                        font: 700 26px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .about-title-white {
                            max-width: 100%;
                        }
                    }
                    .about-text-white {
                        color: #ebebeb;
                        margin-top: 35px;
                        font: 400 16px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .about-text-white {
                            max-width: 100%;
                        }
                    }
                    

                    .exp-blurb-column1 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        margin-top: 64px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .exp-blurb-column1 {
                            width: 100%;
                        }
                    }
                    
                    .exp-blurb-column2 {
                        display: flex;
                        flex-direction: column;
                        align-self: stretch;
                        margin: auto 0;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .exp-blurb-column2 {
                            margin-top: 40px;
                        }
                    }
                    
                    .exp-blurb-title {
                        color: #4030b7;
                        font: 700 44px Work Sans, sans-serif;
                    }
                    .exp-blurb-text {
                        color: var(--Neutral-600, #525b7a);
                        margin-top: 34px;
                        font: 400 16px Work Sans, sans-serif;
                    }
                    
                    .contact-us-btn {
                        align-self: left;
                        justify-content: center;
                        background-color: #4030b7;
                        margin-top: 31px;
                        color: var(--Neutral-White, #fff);
                        text-align: center;
                        padding: 12px 15px;
                        width: 128px;
                        height: 42px;
                        border: none;
                        border-radius: 4px;
                        font: 600 13px Work Sans, sans-serif;
                        cursor: pointer;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .contact-us-btn {
                            //padding: 0 20px;
                        }
                    }

                `}</style>
            </>
        );
        
    }//END renderExperience
    
    
    render() {

        return (
            <div>
                {this.renderBanner()}
                {/*{this.renderAdoptionSection()}*/}
                {/*<AdoptionCarousel />*/}
                {this.renderCallForHelp()}
                {this.renderExperience()}
                {/*{renderSocials()}*/}
                {renderFooter()}
            </div>
        );
        
    }//END render
    
    
}//END class Landing

export default connect()(Landing);
