import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";
import { translate, translatePageText, translateComponentText } from "../i18n";

import gfcrLogoXl from "../assets/images/gfcr-logo[1024x1024].png";
import aboutStats from "../assets/images/about-stats.jpg";



class About extends Component {
    

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderDescription
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderDescription() {

        return (
            <>
                <div className="main-container">

                    <div className="container-row">
                    
                        <div className="row-content">
                            <img
                                loading="lazy"
                                srcSet={gfcrLogoXl}
                                className="logo-extra-large"
                            />
                        </div>
                        
                        <div className="row-content-colored">
                            <div className="row-content-colored-inner">
                                <div className="about-title-white">{translatePageText('about', 'header', 'title')}</div>
                                <div className="about-text-white">
                                    {translatePageText('about', 'paragraph1', 'text')}
                                <br />
                                <br />
                                    {translatePageText('about', 'paragraph2', 'text')}
                                <br />
                                <br />
                                    {translatePageText('about', 'paragraph3', 'text')}
                                <br />
                                <br />
                                    {translatePageText('about', 'paragraph4', 'text')}
                                <br />
                            </div>
                            </div>
                        </div>
                        
                    </div>


                    <div className="container-row">

                        <div className="row-content-75">
                            <img
                                loading="lazy"
                                srcSet={aboutStats}
                                className="logo-extra-large"
                            />
                        </div>

                        <div className="row-content-25">
                            <div className="exp-blurb-column1">
                                
                                <div className="exp-blurb-column2">
                                    <div className="exp-blurb-title">{translateComponentText('team-experience', 'title')}</div>
                                    <div className="exp-blurb-text">{translateComponentText('team-experience', 'text')}</div>
                                    
                                    <Link 
                                        className="contact-us-btn"
                                        to="/contact"
                                    >
                                        {translate('contactUs')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <br />
                    
                </div>
                
                <style jsx>{`
                    .main-container {
                        display: flex;
                        flex-direction: column;
                        align-items: end;
                        padding: 32px 32px 32px;
                        width: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .main-container {
                            padding: 0 20px;
                        }
                    }

                    .container-row {
                        display: flex;
                        margin-top: 15px;
                        justify-content: space-between;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .container-row {
                            flex-wrap: wrap;
                        }
                    }

                    .row-content {
                        //background-color: coral;
                        justify-content: flex-end;
                        border-radius: 1.463px;
                        display: flex;
                        width: 50%;
                        max-width: 100%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content {
                            margin-right: 0px;
                            padding: 0px;
                            width: 100%;
                        }
                    }

                    .row-content-colored {
                        border-radius: 2px;
                        //box-shadow: 0px 14.635px 17.562px -2.927px rgba(17, 24, 39, 0.1),
                        ////0px 5.854px 5.854px -2.927px rgba(17, 24, 39, 0.04);
                        background-color: #4030b7;
                        align-self: center;
                        display: flex;
                        justify-content: center;
                        justify-items: center;
                        align-content: center;
                        align-items: center;

                        width: 50%;
                        max-width: 100%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-colored {
                            justify-content: center;
                            margin-right: 0px;
                            padding: 32px 0px;
                            width: 100%;
                        }
                    }

                    .row-content-colored-inner {
                        //background-color: #8c81e0;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-colored-inner {
                            justify-content: center;
                            margin-right: 0px;
                            padding: 0px;
                            width: 80%;
                        }
                    }


                    .row-content-75 {
                        //background-color: #97f1a9;
                        justify-content: flex-end;
                        border-radius: 1.463px;
                        //box-shadow: 0px 14.635px 17.562px -2.927px rgba(17, 24, 39, 0.1),
                        //0px 5.854px 5.854px -2.927px rgba(17, 24, 39, 0.04);
                        display: flex;
                        width: 65%;
                        max-width: 65%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-75 {
                            margin-right: 0px;
                            padding: 0 20px;
                            width: 100%;
                            max-width: 100%;
                        }
                    }

                    .row-content-25 {
                        //background-color: coral;
                        justify-content: center;
                        align-content: center;
                        border-radius: 1.463px;
                        //box-shadow: 0px 14.635px 17.562px -2.927px rgba(17, 24, 39, 0.1),
                        //0px 5.854px 5.854px -2.927px rgba(17, 24, 39, 0.04);
                        display: flex;
                        width: 35%;
                        max-width: 35%;
                        flex-direction: column;
                        padding: 38px 32px;
                        height: 100%;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .row-content-25 {
                            margin-right: 0px;
                            padding: 0 20px;
                            width: 100%;
                            max-width: 100%;
                        }
                    }


                    .logo-extra-large {
                        aspect-ratio: 1;
                        object-fit: auto;
                        object-position: center;
                        width: 512px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .logo-extra-large {
                            max-width: 100%;
                        }
                    }


                    .about-title-white {
                        color: #fff;
                        font: 700 26px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .about-title-white {
                            max-width: 100%;
                        }
                    }

                    .about-text-white {
                        color: #ebebeb;
                        margin-top: 35px;
                        font: 400 16px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .about-text-white {
                            max-width: 100%;
                        }
                    }


                    .exp-blurb-column1 {
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                        margin-top: 64px;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .exp-blurb-column1 {
                            width: 100%;
                        }
                    }

                    .exp-blurb-column2 {
                        display: flex;
                        flex-direction: column;
                        align-self: stretch;
                        margin: auto 0;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .exp-blurb-column2 {
                            margin-top: 40px;
                        }
                    }

                    .exp-blurb-title {
                        color: #4030b7;
                        font: 700 44px Work Sans, sans-serif;
                    }

                    .exp-blurb-text {
                        color: var(--Neutral-600, #525b7a);
                        margin-top: 34px;
                        font: 400 16px Work Sans, sans-serif;
                    }

                    .contact-us-btn {
                        align-self: left;
                        text-align: center;
                        justify-content: center;
                        border: none;
                        border-radius: 4px;
                        background-color: #4030b7;
                        margin-top: 31px;
                        color: var(--Neutral-White, #fff);
                        padding: 12px 15px;
                        width: 128px;
                        height: 42px;
                        font: 600 13px Work Sans, sans-serif;
                    }
                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .contact-us-btn {
                            margin-bottom: 64px;
                        }
                    }

                `}</style>
            </>
        );

    }//END renderDescription
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderDescription()}
                {renderSocials()}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing

export default connect()(About);
