import React, { Component } from 'react';
import { connect } from 'react-redux';

import {translate, translatePageText} from "../i18n";

import * as actions from "../actions";

import Button from './common/Button';

import styleConstants from "../constants/styleConstants";
import renderSocials from "./common/RenderSocials";
import renderFooter from "./common/RenderFooter";



class Contact extends Component {
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onSubmitContactForm
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    onSubmitContactForm = () => {
        
        // Submit contact form
        const { name, email, reason, phone, message } = this.state;
        const contactData = {
            name: name,
            email: email,
            reason: reason,
            phone: phone,
            message: message
        };
        
        // Validate contact form
        //
        
        // Ensure name, reason, and message are not empty
        if (name === "" || reason === "" || message === "") {
            
            alert(translate("errorAllFields"));
            return;
            
        }//END if
        
        // Ensure email is valid
        if (!this.validateEmail(email)) {
                
            alert(translate("errorInvalidEmail"));
            return;
            
        }//END if
        
        // Ensure phone number is valid
        if (!this.validatePhone(phone)) {
            
            alert(translate("errorInvalidPhone"));
            return;
            
        }//END if
        
        
        this.props.submitContactForm(contactData);
        
    }//END onSubmitContactForm

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onNameChange
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
    onNameChange = (e) => {
        
        this.setState({ name: e.target.value });
    
    }//END handleNameChange

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onEmailChange
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
    onEmailChange = (e) => {
        
        this.setState({ email: e.target.value });
        
    }//END handleEmailChange

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onReasonChange
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
    onReasonChange = (e) => {
        
        this.setState({ reason: e.target.value });
        
    }//END handleReasonChange

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onPhoneChange
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/   
    onPhoneChange = (e) => {
        
        this.setState({ phone: e.target.value });
        
    }//END handlePhoneChange

    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     onMessageChange
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/   
    onMessageChange = (e) => {
        
        this.setState({ message: e.target.value });
        
    }//END handleMessageChange
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     constructor
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/    
    constructor(props) {
        
        super(props);
        
        this.state = {
            name: "",   
            email: "",
            reason: "",
            phone: "",
            message: "",
            placeholderName: translate("senderName"),
            placeholderEmail: translate("email"),
            placeholderReason: translate("senderReason"),
            placeholderPhone: translate("phone"),
            placeholderMessage: translate("senderMessage")
        };
        
    }//END constructor

    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     validateEmail
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    validateEmail = (email) => {

        // Validate email via regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);

    }//END validateEmail
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     validatePhone
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    validatePhone = (phone) => {

        // Validate phone number
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);

    }//END validatePhone
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     renderDescription
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    renderContactForm() {
        
        const { placeholderName, placeholderEmail, placeholderReason, placeholderPhone, placeholderMessage } = this.state;
        const { name, email, reason, phone, message } = this.state;

        return (
            <>
                <div className="main-container">
                    <div className="main-container2">
                        
                        <div className="contact-title">{translatePageText('contact', 'header', 'title')}</div>
                        <div className="contact-blurb">{translatePageText('contact', 'header', 'text')}</div>
                        <br /><br />
                        
                        <div className="input-field-row">
                          
                            <div className="input-field-box">
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder={placeholderName}
                                    value={name}
                                    onChange={this.onNameChange}    
                                />
                            </div>

                            <div className="input-field-box">
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder={placeholderEmail}
                                    value={email}
                                    onChange={this.onEmailChange}
                                />
                            </div>
                            
                        </div>
                        
                        <div className="input-field-row">
                            <div className="input-field-box">
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder={placeholderReason}
                                    value={reason}
                                    onChange={this.onReasonChange}
                                />
                            </div>

                            <div className="input-field-box">
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder={placeholderPhone}
                                    value={phone}
                                    onChange={this.onPhoneChange}
                                />
                            </div>
                            
                        </div>
                        
                        <div className="input-field-row">

                            <textarea 
                                rows="6" 
                                cols="10"
                                value={message}
                                onChange={this.onMessageChange}
                                className="message-input-field" 
                                placeholder={placeholderMessage}>
                                
                            </textarea>

                        </div>
                        
                        <div className="required-field-msg">
                            {/*<span style={{color: 'rgba(196,3,3,1)'}}>*</span>{" "}*/}
                            <span style={{color: 'rgba(102,114,153,1)'}}>
                            {translate("allFieldsRequired")}
                        </span>
                        </div>
                        <Button
                            className="submit-btn"
                            border="none"
                            borderRadius="2px"
                            width={"196px"}
                            height={"42px"}
                            onClick={this.onSubmitContactForm}>
                            {translate('submit')}
                        </Button>
                        {/*<div className="submit-btn">Submit</div>*/}
                    </div>
                    
                </div>
                
                <br />
                <br />
                
                <style jsx="true">{`

                    .main-container {
                        background-color: var(--Neutral-50, #f6f8f7);
                        display: flex;
                        width: 100%;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 400;
                        justify-content: center;
                        padding: 70px 60px;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .main-container {
                            max-width: 100%;
                            padding: 0 20px;
                        }
                    }

                    .main-container2 {
                        display: flex;
                        margin-top: 33px;
                        width: 540px;
                        max-width: 100%;
                        flex-direction: column;
                    }

                    .contact-title {
                        color: var(--Neutral-800, #292e3d);
                        align-self: center;
                        font: 700 26px Work Sans, sans-serif;
                    }

                    .contact-blurb {
                        color: var(--Neutral-800, #292e3d);
                        text-align: center;
                        align-self: center;
                        margin-top: 18px;
                        font: 15px Work Sans, sans-serif;
                    }

                    .input-field-row {
                        display: flex;
                        margin-top: 16px;
                        gap: 16px;
                        justify-content: space-between;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .input-field-row {
                            flex-wrap: wrap;
                        }
                    }


                    .input-field-box {
                        justify-content: space-between;
                        border-radius: 3px;
                        border-color: rgba(224, 227, 235, 1);
                        border-style: solid;
                        border-width: 1px;
                        background-color: #fff;
                        display: flex;
                        color: #ed6565;
                        padding: 0px 8px;
                        width: 50%;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .input-field-box {
                            width: 100%;
                        }
                    }

                    .input-field {
                        font-family: Work Sans, sans-serif;
                        color: var(--Neutral-300, #3a3a3a);
                        border-radius: 3px;
                        border-color: rgba(224, 227, 235, 1);
                        border-style: solid;
                        border-width: 1px;
                        background-color: #fff;
                        width: 100%;
                        //overflow: scroll;
                        display: flex;
                        flex-wrap: wrap;
                        overflow-wrap: normal;

                    }

                    .input-field-text {
                        color: rgb(58, 58, 58);
                        width: 100%;
                        //overflow: scroll;
                        display: flex;
                        flex-wrap: wrap;
                        overflow-wrap: normal;
                        flex-direction: column;
                        align-self: flex-start;
                    }

                    .drop-down-arrow-img {
                        aspect-ratio: 1;
                        object-fit: auto;
                        object-position: center;
                        width: 15px;
                    }

                    .message-input-field {
                        font-family: Work Sans, sans-serif;
                        border-radius: 2.927px;
                        border-color: rgba(224, 227, 235, 1);
                        border-style: solid;
                        border-width: 1px;
                        background-color: #fff;
                        color: var(--Neutral-300, #3a3a3a);
                        justify-content: center;
                        height: 100px;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        white-space: pre-wrap;
                        overflow-wrap: break-word;
                        flex-direction: column;
                        align-self: flex-start;
                        resize: none;
                        padding: 8px 8px;
                    }

                    .required-field-msg {
                        color: var(--Neutral-500, #667299);
                        margin-top: 8px;
                        font: 12px Work Sans, sans-serif;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .required-field-msg {
                            max-width: 100%;
                        }
                    }

                    .submit-btn {
                        font: 600 13px Work Sans, sans-serif;
                        align-self: center;
                        text-align: center;
                        justify-content: center;
                        border-radius: 4px;
                        background-color: #4030b7;
                        margin-top: 21px;
                        width: 196px;
                        height: 42px;
                        max-width: 100%;
                        padding: 12px 0px;
                        color: var(--Neutral-White, #fff);
                        border: none;
                        cursor: pointer;
                    }

                    @media (max-width: ${styleConstants.mobileScreenWidth}px) {
                        .submit-btn {
                            margin-bottom: 32px;
                        }
                    }

                `}</style>
            </>
        );

    }//END renderDescription
    
    
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    //     render
    //------------------------------------------
    /*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    render() {

        return (
            <div>
                {this.renderContactForm()}
                {/*{renderSocials()}*/}
                {renderFooter()}
            </div>
        );

    }//END render


}//END class Landing

export default connect(null, actions)(Contact);
